import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

interface PropTypes {
  label: string;
  id?: string;
  className?: string;
  onUpload: (hasFile: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  button: {
    display: 'flex',
    flexGrow: 0,
    marginLeft: 15
  },
  input: {
    flexGrow: 1
  },
  file: {
    display: 'none'
  }
}));

const getId = (id: string) => `hidden-file-${id}`;

function FileUpload({ label, id = label, className, onUpload }: PropTypes) {
  const [filename, setFilename] = useState('');
  const classes = useStyles();
  const updateFilename = (filename: string) => {
    setFilename(filename);
    onUpload(!!filename);
  };

  return (
    <Box className={clsx(classes.container, className)}>
      <TextField id={id} label={label} variant="filled" disabled className={classes.input} value={filename}/>
      <label htmlFor={getId(id)} className={classes.button}>
        <input type="file" className={classes.file} id={getId(id)} name={id} accept="csv, .csv, .drf, .dr2, .dr3, .dr4" onChange={e => updateFilename(e?.target?.files?.[0]?.name || '')}/>
        <Button variant="contained" color="secondary" component="span">
          Upload
        </Button>
      </label>
    </Box>
  )
}

export default FileUpload;
