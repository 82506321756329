import StatScale from './stat-scale';

interface HorseConfiguration {
  hotJockey: boolean;
  hotTrainer: boolean;
  hotCombo: boolean;
  scratched: boolean;

  trainerPercentage: StatScale;
  jockeyPercentage: StatScale;
  breedingDistance: StatScale;
  breedingSurface: StatScale;
  breedingWins: StatScale;
  classChange: StatScale;
}

export default HorseConfiguration;

export const defaultConfig: HorseConfiguration = {
  hotJockey: false,
  hotTrainer: false,
  hotCombo: false,
  scratched: false,

  trainerPercentage: StatScale.NEUTRAL,
  jockeyPercentage: StatScale.NEUTRAL,
  breedingDistance: StatScale.NEUTRAL,
  breedingSurface: StatScale.NEUTRAL,
  breedingWins: StatScale.NEUTRAL,
  classChange: StatScale.NEUTRAL
};
