import React, { FunctionComponent } from 'react';
import TableCell from '@material-ui/core/TableCell';

import colorRating from './color-rating';

interface PropTypes {
  scale?: number,
  [x:string]: any,
  greyed: boolean
}

const RatedValue: FunctionComponent<PropTypes> = ({ scale = 0, children, greyed, ...others }) => {
  if (Math.abs(scale) > 1) {
    throw new Error(`Scale ${scale} is outside the range [-1.0, 1.0].`);
  }

  const color = colorRating(scale);

  return (
    <TableCell style={{ backgroundColor: greyed ? '#333333' : `rgba(${color[0]},${color[1]},${color[2]},0.5)` }} {...others}>
      { children }
    </TableCell>
  );
}

export default RatedValue;
