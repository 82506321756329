const red = [229, 115, 115];
const yellow = [255, 241, 118];
const green = [129, 199, 132];
export default (scale: number): number[] => {
  let bottom = yellow, top: number[];
  if (scale < 0) {
    top = red;
    scale = -scale;
  } else {
    top = green;
  }

  return bottom.map((b: number, index: number) => [b, top[index]]).map(([b, t]) => b + (t - b) * scale);
}
