import Race from '../models/race';

const data: Race[] = [
  {
    number: 1,
    distance: 1760,
    surface: 'Turf',
    raceType: 'Maiden Claimer',
    restrictions: '3 year olds and up with mares and fillies only',
    purse: 15000,
    claimingPrice: 12500,
    trackRecord: 91.41,
    horses: [
      {
        name: 'Crushing Victory',
        morningLineOdds: '10/1',
        calculatedPowerRating: {
          value: 0,
          scale: 0
        },
        postPosition: {
          value: 1,
          scale: 0
        },
        color: 'B'
      },
      {
        name: 'Toro Femme',
        racingStyle: {
          value: 'Early/Presser',
          scale: 0.5
        },
        morningLineOdds: '15/1',
        powerRating: {
          value: 66.5,
          scale: -0.4
        },
        calculatedPowerRating: {
          value: 27,
          scale: -.0339
        },
        postPosition: {
          value: 2,
          scale: 0
        },
        layoff: {
          value: 57,
          scale: 0.2
        },
        rawLast: {
          value: 26.3,
          scale: 0.3
        },
        fixedLast: {
          value: 28.2,
          scale: -1
        },
        rawDistance: {
          value: 25,
          scale: 0.35
        },
        fixedDistance: {
          value: 25.7,
          scale: 0.32
        },
        rawSurface: {
          value: 25,
          scale: 0.35
        },
        fixedSurface: {
          value: 25.7,
          scale: 0.32
        },
        color: 'GR/RO'
      },
      {
        name: 'Saratoga Ranger',
        racingStyle: {
          value: 'Closer',
          scale: 1
        },
        morningLineOdds: '3/2',
        powerRating: {
          value: 91.9,
          scale: 1
        },
        calculatedPowerRating: {
          value: 39,
          scale: 0.79
        },
        postPosition: {
          value: 3,
          scale: 0
        },
        layoff: {
          value: 25,
          scale: 0.95
        },
        rawLast: {
          value: 25.2,
          scale: 0.6
        },
        fixedLast: {
          value: 24.2,
          scale: 0.86
        },
        rawDistance: {
          value: 25.2,
          scale: 0.6
        },
        fixedDistance: {
          value: 24.2,
          scale: 0.86
        },
        rawSurface: {
          value: 25.2,
          scale: 0.6
        },
        fixedSurface: {
          value: 24.2,
          scale: 0.86
        },
        color: 'CH'
      }
    ]
  },
  {
    number: 2,
    distance: 1530,
    surface: 'Dirt',
    raceType: 'Maiden Claimer',
    restrictions: '2 year olds with fillies only',
    purse: 15000,
    claimingPrice: 12500,
    trackRecord: 81.77,
    horses: [
      {
        name: 'Feeling Awesome',
        racingStyle: {
          value: 'Early/Presser',
          scale: 0
        },
        morningLineOdds: '7/2',
        powerRating: {
          value: 105.5,
          scale: 0
        },
        calculatedPowerRating: {
          value: 30,
          scale: 0
        },
        postPosition: {
          value: 1,
          scale: 0
        },
        layoff: {
          value: 21,
          scale: 0
        },
        rawLast: {
          value: 26.9,
          scale: 0
        },
        fixedLast: {
          value: 25.4,
          scale: 0
        },
        rawDistance: {
          value: 26.3,
          scale: 0
        },
        fixedDistance: {
          value: 25.7,
          scale: 0
        },
        rawSurface: {
          value: 26.9,
          scale: 0
        },
        fixedSurface: {
          value: 25.4,
          scale: 0
        },
        color: 'CH'
      },
      {
        name: 'Pekine Sa',
        racingStyle: {
          value: 'Presser',
          scale: 0
        },
        morningLineOdds: '4/1',
        powerRating: {
          value: 108.2,
          scale: 0
        },
        calculatedPowerRating: {
          value: 29,
          scale: 0
        },
        postPosition: {
          value: 2,
          scale: 0
        },
        layoff: {
          value: 14,
          scale: 0
        },
        rawLast: {
          value: 26.4,
          scale: 0
        },
        fixedLast: {
          value: 28.6,
          scale: 0
        },
        rawDistance: {
          value: 25.1,
          scale: 0
        },
        fixedDistance: {
          value: 25.2,
          scale: 0
        },
        rawSurface: {
          value: 26.4,
          scale: 0
        },
        fixedSurface: {
          value: 28.6,
          scale: 0
        },
        color: 'CH'
      },
      {
        name: 'I\'m Really Sumthin',
        racingStyle: {
          value: 'Early/Presser',
          scale: 0
        },
        morningLineOdds: '6/1',
        powerRating: {
          value: 111.8,
          scale: 0
        },
        calculatedPowerRating: {
          value: 41,
          scale: 0
        },
        postPosition: {
          value: 3,
          scale: 0
        },
        layoff: {
          value: 34,
          scale: 0
        },
        rawLast: {
          value: 24.4,
          scale: 0
        },
        fixedLast: {
          value: 25.1,
          scale: 0
        },
        rawDistance: {
          value: 24.4,
          scale: 0
        },
        fixedDistance: {
          value: 25.1,
          scale: 0
        },
        rawSurface: {
          value: 24.4,
          scale: 0
        },
        fixedSurface: {
          value: 25.1,
          scale: 0
        },
        color: 'CH'
      }
    ]
  }
];

export default data;
