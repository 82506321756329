const env = process.env.NODE_ENV;
const isDev = env === 'development';
const isTest = env === 'test';
const isProd = !isDev && !isProd;

export default {
  validateAnalyzeButton: !isDev,
  uploadToApi: isProd || (process.env.REACT_APP_USE_API === 'true'),
  apiUrl: process.env.REACT_APP_API_URL
}
