export type MinMaxAverage = {
    min: number,
    max: number,
    average: number
};

type MinMaxSum = {
    min: number | null,
    max: number | null,
    sum: number,
    count: number
};

export const determine = (nums: number[], excludeZeroes = false): MinMaxAverage => {
    const minMaxSum = nums.reduce((acc: MinMaxSum, cur: number): MinMaxSum => {
        return {
            min: (acc.min !== null && acc.min < cur) || (cur === 0 && excludeZeroes) ? acc.min : cur,
            max: (acc.max !== null && acc.max > cur) || (cur === 0 && excludeZeroes) ? acc.max : cur,
            sum: acc.sum + cur,
            count: acc.count + (cur === 0 && excludeZeroes ? 0 : 1)
        };
    }, { min: null, max: null, sum: 0, count: 0 });

    return {
        min: minMaxSum.min || 0,
        max: minMaxSum.max || 0,
        average: minMaxSum.count === 0 ? 0 : minMaxSum.sum / minMaxSum.count
    };
};