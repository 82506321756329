import React, { ChangeEvent, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import Race from './models/race';
import { HorseSortableFields } from './models/horse';
import RaceDisplay from './race-display';
import { SortBy, SortDirection } from './models/sort';
import { storageService } from './storage';
import { Scratches } from './models/scratches';
import IconButton from '@material-ui/core/IconButton';

type SortState = {
  sortBy: SortBy<HorseSortableFields>,
  sortDirection: SortDirection
};

const useStyles = makeStyles(theme => ({
  raceNumber: {
    width: '100%',
    maxWidth: '250px'
  },
  topBar: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  raceNumberControls: {
    display: 'flex',
    flexBasis: '25%',
    justifyContent: 'space-around'
  }
}));

const restartHandler = () => {
  storageService.clear();
  window.location.reload();
};

function ResultsPage({ results, scratches, toggleHorseScratched }: { results: Race[], scratches: Scratches, toggleHorseScratched: (raceNumber: number, postPosition: number) => void }) {
  const defaultSortState: SortState = { sortBy: 'postPosition', sortDirection: 'asc' };
  const [raceNumber, setRaceNumber] = useState(1);
  const [sorting, setSorting] = useState(defaultSortState);
  const handleRaceNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const valueAsString = e.target.value + '';
    const newRaceNumber = valueAsString.length ? parseInt(valueAsString, 10) : 0;

    setRaceNumber(newRaceNumber);
  }, [setRaceNumber]);

  const upRaceNumber = useCallback(() => {
    setRaceNumber(n => {
      if (n < results.length) {
        return n + 1;
      }

      return n;
    });
  }, [setRaceNumber, results.length]);

  const downRaceNumber = useCallback(() => {
    setRaceNumber(n => {
      if (n > 1) {
        return n - 1;
      }

      return n;
    });
  }, [setRaceNumber]);

  const classes = useStyles();
  return (
    <div>
      <Box className={classes.topBar}>
        <Box className={classes.raceNumberControls}>
          <TextField
            className={classes.raceNumber}
            label="Race Number"
            value={raceNumber}
            type="number"
            inputProps={{
              min: 1,
              max: results.length
            }}
            onChange={handleRaceNumberChange} />
            <IconButton onClick={downRaceNumber}>
              <ArrowLeft />
            </IconButton>
            <IconButton onClick={upRaceNumber}>
              <ArrowRight />
            </IconButton>
        </Box>
        <Button variant="contained" onClick={restartHandler}>
          Restart
        </Button>
      </Box>
      <RaceDisplay
        scratches={scratches[raceNumber - 1]}
        race={results[raceNumber - 1]}
        sortBy={sorting.sortBy}
        sortDirection={sorting.sortDirection}
        onSortChange={(sortBy, sortDirection) => setSorting({ sortBy, sortDirection })}
        toggleHorseScratch={toggleHorseScratched}
      />
    </div>
  );
}

export default ResultsPage;
