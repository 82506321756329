import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import LoadingPage from './loading-page';
import ResultsPage from './results-page';
import Race from './models/race';
import { storageService } from './storage';
import { defaultScratches, Scratches } from './models/scratches';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    margin: 5
  }
}));

function App() {
  const classes = useStyles();
  const initialRaces: Race[] = storageService.loadRaces() || [];
  const initialScratches: Scratches = initialRaces.length > 0 ? (storageService.loadScratches() || defaultScratches(initialRaces)) : [];
  const [results, setResults] = useState(initialRaces);
  const [scratches, setScratches] = useState(initialScratches);

  const handleResult = useCallback((raceResults: Race[]) => {
    storageService.storeRaces(raceResults);
    setScratches(defaultScratches(raceResults));
    setResults(raceResults);
  }, [setResults]);

  const handleToggleScratched = useCallback((raceNumber: number, postPosition: number) => {
    setScratches(prevScratches => {
      const newScratches = prevScratches.map(s => [...s]);
      newScratches[raceNumber - 1][postPosition - 1] = !newScratches[raceNumber - 1][postPosition - 1];

      return newScratches;
    });
  }, [setScratches]);

  useEffect(() => {
    if (scratches.length) {
      storageService.storeScratches(scratches);
    }
  }, [scratches]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h5" color="inherit">
            Past Performances Analyzer
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        { results.length ? <ResultsPage results={results} scratches={scratches} toggleHorseScratched={handleToggleScratched}/> : <LoadingPage onResults={handleResult}/> }
      </div>
    </div>
  );
}

export default App;
