type RatedData<T> = {
  value: T,
  scale: number // from -1.0 to 1.0
}

export default RatedData;

export const scale = <T extends number>(min: number, max: number, average: number, value: T): number => {
  if (max - min < 1e-6) {
    return 0;
  }
  
  const from = average;
  const to = value < average ? min : max;
  
  let percent = (value - from) / (to - from);
  if (value <= average) {
    percent = -percent;
  }
  
  return percent;
};
