import React from 'react';
import _1 from './images/1.png';
import _2 from './images/2.png';
import _3 from './images/3.png';
import _4 from './images/4.png';
import _5 from './images/5.png';
import _6 from './images/6.png';
import _7 from './images/7.png';
import _8 from './images/8.png';
import _9 from './images/9.png';
import _10 from './images/10.png';
import _11 from './images/11.png';
import _12 from './images/12.png';
import _13 from './images/13.png';
import _14 from './images/14.png';

const images = [
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  _9,
  _10,
  _11,
  _12,
  _13,
  _14
];

function PostPosition({ position, ...props }: { position: number }) {
  if (position < 1 || position > 14) {
    throw new Error('Invalid number supplied: ' + position);
  }

  const truncated = Math.trunc(position);
  return (
    <img src={images[truncated - 1]} alt={`${position}`} />
  );
}

export default PostPosition;
