import Race from './models/race';
import { Scratches } from './models/scratches';

const racesStorageKey = 'pp-analyzer_race-details';
const scratchesStorageKey = 'pp-analyzer_scratch-details';

export const storageService = {
    storeRaces: (races: Race[]) => {
        window.localStorage.setItem(racesStorageKey, JSON.stringify(races));
    },
    loadRaces: (): Race[] | null => {
        const stored = window.localStorage.getItem(racesStorageKey);
        if (stored) {
            return JSON.parse(stored);
        }

        return null;
    },
    storeScratches: (scratches: Scratches) => {
        window.localStorage.setItem(scratchesStorageKey, JSON.stringify(scratches));
    },
    loadScratches: (): Scratches | null => {
        const stored = window.localStorage.getItem(scratchesStorageKey);
        if (stored) {
            return JSON.parse(stored);
        }

        return null;
    },
    clear: () => {
        window.localStorage.removeItem(racesStorageKey);
        window.localStorage.removeItem(scratchesStorageKey);
    }
};