import React, { useState, FormEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import FileUpload from './file-upload';
import Race from './models/race';
import api from './api';
import config from './config';

const useStyles = makeStyles(theme => ({
  root: {
    width: '45%',
    minWidth: 500
  },
  fileUpload: {
  }
}));

const stop = (e: FormEvent<HTMLFormElement>) => {
  console.log(e);
  e.preventDefault();

  return api.uploadForm(new FormData(e.target as HTMLFormElement));
};

const validateFiles = (files: boolean[]): boolean => {
  return config.validateAnalyzeButton && files.some(exists => !exists);
};

function LoadingPage({ onResults }: { onResults: (input: Race[]) => void }) {
  const classes = useStyles();

  const [raceDetailsFile, setRaceDetailsFile] = useState(false);
  const [horseDetailsFile, setHorseDetailsFile] = useState(false);
  const [pastPerformancesFile, setPastPerformancesFile] = useState(false);
  const [brisRatingsFile, setBrisRatingsFile] = useState(false);
  const files = [raceDetailsFile, horseDetailsFile, pastPerformancesFile, brisRatingsFile];

  return (
    <form className={classes.root} onSubmit={e => stop(e).then(onResults)}>
      <FileUpload id="race-details" label="Race details (file 1)" className={classes.fileUpload} onUpload={hasFile => setRaceDetailsFile(hasFile)}/>
      <FileUpload id="horse-details" label="Horse details (file 2)" className={classes.fileUpload} onUpload={hasFile => setHorseDetailsFile(hasFile)}/>
      <FileUpload id="past-performances" label="Past performances (file 3)" className={classes.fileUpload} onUpload={hasFile => setPastPerformancesFile(hasFile)}/>
      <FileUpload id="bris-ratings" label="BRIS ratings (file 4)" className={classes.fileUpload} onUpload={hasFile => setBrisRatingsFile(hasFile)}/>
      <Button type="submit" disabled={validateFiles(files)} variant="contained" color="primary">
        Analyze
      </Button>
    </form>
  )
}

export default LoadingPage;
