import PowerRatingConfiguration from '../models/power-rating-configuration';

const defaultDelta = { good: 1, bad: -1 };

const defaultConfig: PowerRatingConfiguration = {
  lastSpeedScale: 0.5,
  distanceSpeedScale: 0.5,
  surfaceSpeedScale: 0.5,
  powerRatingScale: 0.5,

  layoffPoints: defaultDelta,
  layoffDays: { good: 30, bad: 60 },
  unknownSpeedPoints: 0,
  hotJockeyPoints: 0,
  hotTrainerPoints: 0,
  comboPoints: 0,

  jockeyPercentage: defaultDelta,
  trainerPercentage: defaultDelta,
  breedingDistance: defaultDelta,
  breedingSurface: defaultDelta,
  breedingWins: defaultDelta,
  classChange: defaultDelta,
  racingStyle: defaultDelta
};

export default defaultConfig;
