import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Horse, { ColorDisplay } from './models/horse';
import PostPosition from './post-position';
import RatedValue from './rated-value';
import { defaultConfig } from './models/horse-configuration';
import calculator from './power-rating-calculator';
import { colorMapping } from './models/horse';
import { MinMaxAverage } from './models/minMaxAverage';
import { scale } from './models/rated-data';

type Props = {
  horse: Horse,
  minMaxAverageCalculatedRating: MinMaxAverage,
  scratched: boolean,
  toggleScratched: (postPosition: number) => void
};

const useStyles = makeStyles(theme => ({
  cell: {
    alignItems: 'stretch',
    justifyContent: 'stretch',
    borderLeft: '1px solid rgb(224, 224, 224)'
  },
  postPositionCell: {
    cursor: 'pointer'
  }
}));

const colorDisplays: Record<string, ColorDisplay> = colorMapping;
const getColor = (colorCode: string): ColorDisplay => colorDisplays[colorCode] || { name: colorCode, background: 'inherit', color: 'inherit' };

function HorseDisplay({ horse, minMaxAverageCalculatedRating, scratched, toggleScratched }: Props) {
  const classes = useStyles();
  const color = getColor(horse.color);
  const calculatedPowerRating = calculator.calculate(horse, defaultConfig);
  const calculatedPowerRatingScale = calculatedPowerRating === 0 ? 0 : scale(minMaxAverageCalculatedRating.min, minMaxAverageCalculatedRating.max, minMaxAverageCalculatedRating.average, calculatedPowerRating);
  const scratchedStyle = scratched ? { backgroundColor: '#333333' } : {};
  const handleToggleScratched = useCallback(() => {
    toggleScratched(horse.postPosition.value);
  }, [toggleScratched, horse.postPosition.value]);

  return (
    <TableRow>
      <TableCell className={classes.postPositionCell} onClick={handleToggleScratched}>
        <PostPosition position={horse.postPosition.value} />
      </TableCell>
      <TableCell className={classes.cell} style={scratchedStyle}>{horse.name}</TableCell>
      <TableCell className={classes.cell} style={scratched ? scratchedStyle : { background:color.background, color: color.textColor }}>{color.name}</TableCell>
      <TableCell className={classes.cell} style={scratchedStyle}>{horse.morningLineOdds}</TableCell>
      <RatedValue className={classes.cell} greyed={scratched} scale={horse.racingStyle?.scale}>{horse.racingStyle?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.powerRating?.scale}>{horse.powerRating?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={calculatedPowerRatingScale}>{calculatedPowerRating.toFixed(2)}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.layoff?.scale}>{horse.layoff?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.rawLast?.scale}>{horse.rawLast?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.fixedLast?.scale}>{horse.fixedLast?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.rawDistance?.scale}>{horse.rawDistance?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.fixedDistance?.scale}>{horse.fixedDistance?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.rawSurface?.scale}>{horse.rawSurface?.value}</RatedValue>
      <RatedValue className={classes.cell} greyed={scratched} align="right" scale={horse.fixedSurface?.scale}>{horse.fixedSurface?.value}</RatedValue>
    </TableRow>
  );
}

export default HorseDisplay;
