import api from './api';
import HorseConfiguration from './models/horse-configuration';
import Horse from './models/horse';
import StatScale from './models/stat-scale';
import { Delta } from './models/power-rating-configuration';

const scale = (value: number | undefined, scale: number, max?: number): number => {
  if (value === undefined) {
    return 0;
  }

  return (max ? (max - value) : value) * scale;
};

const delta = (stat: StatScale, points: Delta): number => {
  return stat === StatScale.GOOD ? points.good : stat === StatScale.BAD ? points.bad : 0;
};

const getScale = (value: number | undefined, config: Delta): StatScale => {
  if (!value) {
    return StatScale.NEUTRAL;
  }

  if (value <= config.good) {
    return StatScale.GOOD;
  } else if (value > config.bad) {
    return StatScale.BAD;
  }

  return StatScale.NEUTRAL;
};

export default {
  calculate(horse: Horse, configuration: HorseConfiguration): number {
    const powerRatingConfig = api.loadPowerRating();
    let total = 0;

    // Power Rating
    total += scale(horse.powerRating?.value, powerRatingConfig.powerRatingScale);

    // Speeds
    total += scale(horse.rawLast?.value, powerRatingConfig.lastSpeedScale, 25) || powerRatingConfig.unknownSpeedPoints;
    total += scale(horse.fixedLast?.value, powerRatingConfig.lastSpeedScale, 25) || powerRatingConfig.unknownSpeedPoints;
    total += scale(horse.rawDistance?.value, powerRatingConfig.distanceSpeedScale, 25) || powerRatingConfig.unknownSpeedPoints;
    total += scale(horse.fixedDistance?.value, powerRatingConfig.distanceSpeedScale, 25) || powerRatingConfig.unknownSpeedPoints;
    total += scale(horse.rawSurface?.value, powerRatingConfig.surfaceSpeedScale, 25) || powerRatingConfig.unknownSpeedPoints;
    total += scale(horse.fixedSurface?.value, powerRatingConfig.surfaceSpeedScale, 25) || powerRatingConfig.unknownSpeedPoints;

    // Hot
    total += configuration.hotJockey ? powerRatingConfig.hotJockeyPoints : 0;
    total += configuration.hotTrainer ? powerRatingConfig.hotTrainerPoints : 0;
    total += configuration.hotCombo ? powerRatingConfig.comboPoints : 0;

    // Deltas
    total += delta(configuration.trainerPercentage, powerRatingConfig.trainerPercentage);
    total += delta(configuration.jockeyPercentage, powerRatingConfig.jockeyPercentage);
    total += delta(configuration.breedingDistance, powerRatingConfig.breedingDistance);
    total += delta(configuration.breedingSurface, powerRatingConfig.breedingSurface);
    total += delta(configuration.breedingWins, powerRatingConfig.breedingWins);
    total += delta(configuration.classChange, powerRatingConfig.classChange);

    // Racing Style
    const styleScale = getScale(-(horse.racingStyle?.scale || 0), { good: -0.33, bad: 0.33 });
    total += delta(styleScale, powerRatingConfig.racingStyle);

    // layoff
    const layoffScale = getScale(horse.layoff?.value, powerRatingConfig.layoffDays);
    total += delta(layoffScale, powerRatingConfig.layoffPoints);

    return total;
  }
}
