import data from './data/results-data';
import defaultPowerRatingConfig from './data/default-power-rating';
import config from './config'
import Race from './models/race';
import PowerRatingConfiguration from './models/power-rating-configuration';

const powerRatingKey = 'power-ratings-configuration';
const setPowerRating = (config: PowerRatingConfiguration) => {
  sessionStorage.setItem(powerRatingKey, JSON.stringify(config));
};

export default {
  uploadForm: (uploadData: FormData): Promise<Race[]> => {
    if (config.uploadToApi) {
      return fetch(`${config.apiUrl}/api/upload`, {
        method: 'POST',
        body: uploadData
      })
      .then(response => response.json());
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(data), 1500);
    });
  },
  loadPowerRating: (): PowerRatingConfiguration => {
    const config = sessionStorage.getItem(powerRatingKey);
    if (!config) {
      setPowerRating(defaultPowerRatingConfig);
      return defaultPowerRatingConfig;
    }

    return JSON.parse(config);
  },
  setPowerRating
}
