import React, { useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Race from './models/race';
import Horse, { HorseSortableFields } from './models/horse';
import HorseDisplay from './horse-display';
import { SortBy, SortDirection } from './models/sort';
import { defaultConfig } from './models/horse-configuration';
import calculator from './power-rating-calculator';
import { determine } from './models/minMaxAverage';

const sortHorses = (horses: Horse[], sortBy: SortBy<HorseSortableFields>, sortDirection: SortDirection) => {
  const comparator = (left: Horse, right: Horse) => {
    const leftProp = left[sortBy]?.value || 0;
    const rightProp = right[sortBy]?.value || 0;

    let comparison = leftProp < rightProp ? -1 : leftProp > rightProp ? 1 : 0;
    if (comparison === 0) {
      comparison = left.postPosition.value - right.postPosition.value;
    }

    if (sortDirection === 'desc') {
      comparison = -comparison;
    }

    return comparison;
  };

  const copy = [...horses];
  copy.sort(comparator);

  return copy;
}

type HeaderCell = {
  id: keyof Horse,
  label: string,
  sortField?: SortBy<HorseSortableFields>,
  align: 'left' | 'right'
}

const headerCells: HeaderCell[] = [
  { id: 'postPosition', label: 'Post Position', sortField: 'postPosition', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'color', label: 'Color', align: 'left' },
  { id: 'morningLineOdds', label: 'Morning Line Odds', align: 'left' },
  { id: 'racingStyle', label: 'Racing Style', align: 'left' },
  { id: 'powerRating', label: 'Power Rating', sortField: 'powerRating', align: 'right' },
  { id: 'calculatedPowerRating', label: 'Calculated Power Rating', sortField: 'calculatedPowerRating', align: 'right' },
  { id: 'layoff', label: 'Layoff', sortField: 'layoff', align: 'right' },
  { id: 'rawLast', label: 'Raw Time (Last)', sortField: 'rawLast', align: 'right' },
  { id: 'fixedLast', label: 'Fixed Time (Last)', sortField: 'fixedLast', align: 'right' },
  { id: 'rawDistance', label: 'Raw Time (Distance)', sortField: 'rawDistance', align: 'right' },
  { id: 'fixedDistance', label: 'Fixed Time (Distance)', sortField: 'fixedDistance', align: 'right' },
  { id: 'rawSurface', label: 'Raw Time (Surface)', sortField: 'rawSurface', align: 'right' },
  { id: 'fixedSurface', label: 'Fixed Time (Surface)', sortField: 'fixedSurface', align: 'right' }
];

const handleSortChange = (sortBy: SortBy<HorseSortableFields>, sortDirection: SortDirection, cell: HeaderCell, onSortChange: (sortBy: SortBy<HorseSortableFields>, sortDirection: SortDirection) => void) => {
  if (cell.sortField === sortBy) {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    return onSortChange(sortBy, newSortDirection);
  } else if (cell.sortField) {
    return onSortChange(cell.sortField, 'asc');
  }
};

function RaceDisplay({ race, sortBy, sortDirection, onSortChange, scratches, toggleHorseScratch }: { race: Race, sortBy: SortBy<HorseSortableFields>, sortDirection: SortDirection, onSortChange: (sortBy: SortBy<HorseSortableFields>, sortDirection: SortDirection) => void, scratches: boolean[], toggleHorseScratch: (raceNumber: number, postPosition: number) => void }) {
  const horses = sortHorses(race.horses, sortBy, sortDirection);
  const calculatedPowerRatings = horses.map(h => calculator.calculate(h, defaultConfig));
  const minMaxAverage = determine(calculatedPowerRatings, true);
  const toggleScratched = useCallback((postPosition: number) => {
    toggleHorseScratch(race.number, postPosition);
  }, [toggleHorseScratch, race.number]);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headerCells.map(cell => (
              <TableCell
                align={cell.align}
                key={cell.id}
                sortDirection={cell.sortField === sortBy ? sortDirection : false}
              >
                {cell.sortField ? (
                  <TableSortLabel
                    active={sortBy === cell.sortField}
                    direction={sortBy === cell.sortField ? sortDirection : 'asc'}
                    onClick={e => handleSortChange(sortBy, sortDirection, cell, onSortChange)}
                  >
                    {cell.label}
                  </TableSortLabel>
                ) : cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {horses.map(horse => <HorseDisplay scratched={scratches[horse.postPosition.value - 1]} toggleScratched={toggleScratched} key={horse.postPosition.value} horse={horse} minMaxAverageCalculatedRating={minMaxAverage} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RaceDisplay;
