import RatedData from './rated-data';

export interface HorseSortableFields {
  readonly powerRating?: RatedData<number>;
  readonly calculatedPowerRating: RatedData<number>;
  readonly postPosition: RatedData<number>;
  readonly layoff?: RatedData<number>;
  readonly rawLast?: RatedData<number>;
  readonly fixedLast?: RatedData<number>;
  readonly rawDistance?: RatedData<number>;
  readonly fixedDistance?: RatedData<number>;
  readonly rawSurface?: RatedData<number>;
  readonly fixedSurface?: RatedData<number>;
}

export default interface Horse extends HorseSortableFields {
  readonly name: string;
  readonly racingStyle?: RatedData<string>;
  readonly morningLineOdds: string;
  readonly color: HorseColor
}

export type HorseColor = keyof typeof colorMapping;
export type ColorDisplay = {
  name: string,
  background: string,
  textColor: string
};

export const colorMapping = {
  B: {
    name: 'Bay',
    background: '#a25d2d',
    textColor: 'inherit'
  },
  'GR/RO': {
    name: 'Gray',
    background: '#DBDBDB',
    textColor: 'inherit'
  },
  DKBBR: {
    name: 'Dark Bay',
    background: '#4c3e3e',
    textColor: '#EEEEEE'
  },
  CH: {
    name: 'Chestnut',
    background: '#a96d48',
    textColor: 'inherit'
  }
};
